<template>
  <b-container v-can="'purchase.order.receipt.view'">
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">
              Listado de recepciones de órdenes de compras
            </h4>
          </template>
          <template v-slot:headerAction>
            <b-button
              v-can="'purchase.order.receipt.update'"
              variant="primary"
              @click="$bvModal.show('bv-modal-example')"
              class="mr-2"
            >
              Nueva recepción
            </b-button>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col md="3" class="ml-auto">
                <b-form-group>
                  <b-input-group>
                    <b-form-input
                      id="filter-input"
                      :disabled="!Array.isArray(items) || (items.length === 0 && isBusy === false)"
                      v-model="filter"
                      type="search"
                      placeholder="Buscar..."
                    ></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
             </b-row>
             <b-row>
              <b-col>
            <b-table
              hover
              :items="items"
              :fields="fields"
              :per-page="perPage"
              :current-page="currentPage"
              :filter="filter"
              @filtered="onFiltered"
              :busy=" !Array.isArray(items) || items.length === 0 ? true : false || isBusy == true"
            >
              <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <div v-if="isBusy">
                        <b-spinner type="grow" class="align-middle"></b-spinner>
                        <strong> Cargando datos...</strong>
                      </div>
                      <span class="h5" v-else>Sin registros</span>
                    </div>
                  </template>
              <template v-slot:cell(status)="data">
                <b-badge pill variant="success">{{ data.item.status }}</b-badge>
              </template>
              <template v-slot:cell(acciones)="data">
                <b-button
                       v-b-tooltip.hover
                        title="Ver detalles"
                        variant=" iq-bg-primary mr-1"
                        size="sm"
                        :to="{
                          name: 'purchase_reception.details',
                          params: { id: data.item.id },
                        }"
                        ><i class="ri-eye-line ml-1"></i
                      ></b-button>
              </template>
            </b-table>
            <b-pagination
                  v-if="totalRows > perPage"
                  class="mt-3"
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  pills
                  size="sm"
                  align="center"
                ></b-pagination>
              </b-col>
             </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <div>
      <b-modal
        ref="ubication"
        size="lg"
        scrollable
        id="bv-modal-example"
        hide-footer
      >
        <template #modal-title> Órdenes de compra facturadas</template>
        <b-table
          responsive
          :items="rows"
          :fields="columns"
          class="mb-0 table-borderless text-center"
        >
          <template v-slot:cell(status)="data">
            <b-badge pill variant="info">{{ data.item.status }}</b-badge>
          </template>
          <template v-slot:cell(options)="data">
            <b-row>
              <b-col sm="5" md="5" lg="5" class="ml-2">
                <b-button
                 v-b-tooltip.hover
                  title="Ver detalles"
                  variant=" iq-bg-primary"
                  size="sm"
                  :to="{ name: 'orders_requests.show', params: { id: data.item.id } }"
                  target="_blank"
                  ><i class="ri-eye-line ml-1"></i>
                </b-button>
              </b-col>
              <b-col sm="5" md="5" lg="5">
                 <b-button
                  v-b-tooltip.hover
                        title="Procesar Orden"
                    variant=" iq-bg-info"
                    size="sm"
                    :to="{
                      name: 'purchase_reception.show',
                      params: { id: data.item.id },
                    }"
                    ><i class="ri-inbox-archive-line ml-1"></i>
                  </b-button>
              </b-col>
            </b-row>
          </template>
        </b-table>
      </b-modal>
    </div>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
export default {
  name: 'ListPurchaseReception',
  async created () {
    this.$store.commit("SET_BREADCRUMB", this.breadcrumb);
    // CARGAR ORDENES DE COMPRAS
    this.isBusy = true
    let data = await this.$store.dispatch('orders/listPurchaseOrders')
    data = data.filter((item) => item.status === 3)
    this.rows = data.map((item) => ({
      id: item.id,
      codigo: item.correlative,
      area: item.area.name,
      status: item.status_description,
      create: item.created_at,
      user: item.user_applicant.fullname,
      editable: false
    }))

    // CARGAR ORDENES RECIBIDAS
    const res = await this.$store.dispatch('reception/listReception')
    this.items = res.map((item) => ({
      id: item.id,
      item: item.purchase_order.correlative,
      user: item.purchase_order.user_applicant.fullname,
      location: item.location.name,
      area: item.purchase_order.area.name,
      date: item.created_at,
      status: item.status === null ? 'Recibida' : item.status
    }))
    this.totalRows = this.items.length
    this.isBusy = false
  },
  mounted () {
    core.index()
  },
  methods: {
    onFiltered (filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  },
  data () {
    return {
      columns: [
        { label: 'Código', key: 'codigo', class: 'text-center' },
        { label: 'Área', key: 'area', class: 'text-center' },
        { label: 'Usuario', key: 'user', class: 'text-center' },
        { label: 'Fecha de Creación', key: 'create', class: 'text-center' },
        { label: 'Estado', key: 'status', class: 'text-center' },
        { label: 'Opciones', key: 'options', class: 'text-center' }
      ],
      col: [{ label: 'PRODUCTO', key: 'name', class: 'text-center' }],
      rows: [],
      totalRows: 1,
      filter: null,
      perPage: 10,
      currentPage: 1,
      isBusy: false,
      fields: [
        { label: 'Código de Orden', key: 'item', class: 'text-center' },
        { label: 'Usuario', key: 'user', class: 'text-center' },
        { label: 'Fecha', key: 'date', class: 'text-center' },
        { label: 'Área', key: 'area', class: 'text-center' },
        { label: 'Ubicación', key: 'location', class: 'text-center' },
        { label: 'Estado', key: 'status', class: 'text-center' },
        { label: 'Acciones', key: 'acciones', class: 'text-center' }
      ],
      items: [],
      detaills: '',
      detailsList: [],
      breadcrumb: [
        {
          html: 'Inicio',
          to: '/'
        },
        {
          text: 'Recepción de órdenes de compras',
          active: true
        }
      ]
    }
  }
}
</script>
<style>
</style>
